import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
const Tournament = lazy(() => import('./pages/tournament'))
const Tournaments = lazy(() => import('./pages/tournaments'))
const Academy = lazy(() => import('./pages/academy'))
const More = lazy(() => import('./pages/academy/more'))
const Basicknowledge = lazy(() => import('./pages/academy/basicknowledge'))
const Specialfeature = lazy(() => import('./pages/academy/specialfeature'))
const Securitiesfinance = lazy(() => import('./pages/academy/securitiesfinance'))
const Register = lazy(() => import('./pages/register'))
const Invest = lazy(() => import('./pages/invest'))
const Invests = lazy(() => import('./pages/invests'))
const Invests_download = lazy(() => import('./components/RegisterThirdPartyInvests/download'))
const Download = lazy(() => import('./pages/download'))
const Exchange = lazy(() => import('./pages/exchange'))
const Register2 = lazy(() => import('./pages/register2'))

// eslint-disable-next-line import/no-anonymous-default-export

//impactcascade.com   初始页invest
export default () => {
  return (
    <BrowserRouter >
      <Switch>
      <Suspense fallback={< ></> }>
        <Route exact path="/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Register2}/>
        <Route  path="/tournament" component={Tournament} />
        <Route  path="/tournaments" component={Tournaments} />
        <Route  path="/academy" component={Academy} />
        <Route  path="/more" component={More} />
        <Route  path="/basicknowledge" component={Basicknowledge} />
        <Route  path="/securitiesfinance" component={Securitiesfinance} />
        <Route  path="/specialfeature" component={Specialfeature} />
        <Route  path="/register/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Register} />
        <Route  path="/invests/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Invests} />
        <Route  path="/download/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Download} />
        <Route  path="/invests_download/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Invests_download} />
        {/* impactcascade.com */}
        <Route  path="/invest/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Invest} />
        <Route  path="/exchange" component={Exchange} />
        <Route  path="/register2/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Register2} />
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};
